export const typeOptions = [
  { title: 'Film', value: 'Film' },
  { title: 'Short Film', value: 'Short Film' },
  { title: 'Documentary', value: 'Documentary' },
  { title: 'TV Series', value: 'TV Series' },
  { title: 'Web Series', value: 'Web Series' },
];

export const statusOptions = [
  { title: 'Public', value: 'public' },
  { title: 'Private', value: 'private' },
];

export const languageOptions = [
  { title: 'Bengali', value: 'Bengali' },
  { title: 'Chinese', value: 'Chinese' },
  { title: 'English', value: 'English' },
  { title: 'French', value: 'French' },
  { title: 'German', value: 'German' },
  { title: 'Gujarati', value: 'Gujarati' },
  { title: 'Hindi', value: 'Hindi' },
  { title: 'Italian', value: 'Italian' },
  { title: 'Japanese', value: 'Japanese' },
  { title: 'Korean', value: 'Korean' },
  { title: 'Marathi', value: 'Marathi' },
  { title: 'Nepali', value: 'Nepali' },
  { title: 'Russian', value: 'Russian' },
  { title: 'Spanish', value: 'Spanish' },
  { title: 'Tamil', value: 'Tamil' },
  { title: 'Telugu', value: 'Telugu' },
];
